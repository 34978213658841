import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  container: {
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    backgroundColor: "#efefef"
  },
  menu: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflow: "hidden",
    minWidth: "13rem"
  },
  content: {
    flexGrow: 1,
    overflowY: "scroll"
  }
});
