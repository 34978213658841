import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../utils/styling/theme";

export default makeStyles({
  container: {
    overflowY: "scroll",
    scrollSnapType: "y mandatory"
  },
  section: {
    height: `${window.innerHeight}px`,
    scrollSnapAlign: "start",
    display: "flex",
    position: "relative",
    "@media(max-width: 700px)": {
      flexDirection: "column",
      justifyContent: "center"
    }
  },
  left: {
    display: "flex",
    alignItems: "center",
    width: "40%",
    paddingLeft: "4rem",
    textAlign: "right",
    "@media(max-width: 960px)": {
      paddingLeft: 0
    },
    //Below 700px
    "@media(max-width: 700px)": {
      order: 2,
      width: "100%",
      textAlign: "center",
      padding: "0 2rem",
      flex: 1
    }
  },
  right: {
    width: "60%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    // Below 700px
    "@media(max-width: 700px)": {
      width: "100%",
      flexGrow: 1,
      order: 1,
      flex: 1
    }
  },
  imageContainer: {
    width: "80%",
    height: "70%",
    "@media(min-width: 1280px)": {
      height: "80%",
      width: "70%"
    },
    "@media(max-width: 700px)": {
      width: "70%",
      height: "80%"
    }
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderBottomRightRadius: "20px",
    borderTopLeftRadius: "20px"
  },
  // LARGE MOUSE SCROLL ICON
  // =======================
  scrollIcon: {
    "@media(max-width: 700px)": {
      display: "none"
    },
    position: "absolute",
    top: "95%",
    left: "45%",
    width: "30px",
    height: "52.5px",
    marginLeft: "-20px",
    marginTop: "-35px",
    boxShadow: `inset 0 0 2px 0px ${theme.palette.secondary.main}`,
    borderRadius: "25px",
    "&:before": {
      position: "absolute",
      left: "50%",
      content: "''",
      width: "8px",
      height: "8px",
      background: theme.palette.secondary.main,
      marginLeft: "-4px",
      top: "8px",
      borderRadius: "4px",
      animationDuration: "2s",
      animationIterationCount: "infinite",
      animationName: "$scroll"
    }
  },
  "@keyframes scroll": {
    from: { opacity: 1 },
    to: { opacity: 0, transform: "translateY(34.5px)" }
  }
});
