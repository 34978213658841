import React, { Fragment, useState } from "react";
import { Link } from "react-router-dom";

//MUI
import Typography from "@material-ui/core/Typography";

export default props => {
  const { variant, pathname, classes } = props;
  const [hover, setHover] = useState(pathname);

  function active(link) {
    if (hover === link) {
      return `${classes.link} ${classes.active}`;
    } else {
      return classes.link;
    }
  }

  return (
    <Fragment>
      <Link
        className={active("/content")}
        to="/content"
        onMouseEnter={() => setHover("/content")}
        onMouseLeave={() => setHover(pathname)}
      >
        <Typography variant={variant}>CONTENT</Typography>
      </Link>
      <Link
        className={active("/cast-me")}
        to="/cast-me"
        onMouseEnter={() => setHover("/cast-me")}
        onMouseLeave={() => setHover(pathname)}
      >
        <Typography variant={variant}>RESUMÉ</Typography>
      </Link>
      <Link
        className={active("/bio")}
        to="/bio"
        onMouseEnter={() => setHover("/bio")}
        onMouseLeave={() => setHover(pathname)}
      >
        <Typography variant={variant}>BIO</Typography>
      </Link>
      <Link
        className={active("/reach-out")}
        to="/reach-out"
        onMouseEnter={() => setHover("/reach-out")}
        onMouseLeave={() => setHover(pathname)}
      >
        <Typography variant={variant}>CONTACT</Typography>
      </Link>
    </Fragment>
  );
};
