import React from "react";

// Material UI Core
import Typography from "@material-ui/core/Typography";

//Icons
import ErrorIcon from "@material-ui/icons/Error";

export default props => {
  const { children, classes } = props;

  return (
    <div className={classes.containerError} style={{ color: "red" }}>
      <ErrorIcon className={classes.iconError} />
      <Typography variant="subtitle2" className={classes.error}>
        {children}
      </Typography>
    </div>
  );
};
