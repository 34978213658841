import React, { useState, useEffect } from "react";

import Nav from "../Nav";
import Logo from "../Logo";
import useStyles from "./Menu.style";

export default props => {
  const { pathname } = props.location;
  const [checked, changeChecked] = useState(true);
  const classes = useStyles();

  useEffect(() => {
    changeChecked(c => !c);
  }, [pathname]);

  return (
    <div className={classes.navigation}>
      <input
        type="checkbox"
        className={classes.checkbox}
        id="navigationToggle"
        checked={checked}
      />
      <label
        onClick={() => changeChecked(!checked)}
        className={classes.navigationButton}
        htmlFor="navigationToggle"
      >
        <span className={classes.icon}>&nbsp;</span>
      </label>

      <div className={classes.background}>&nbsp;</div>

      <nav className={classes.nav}>
        <div className={classes.linksBox}>
          <Nav pathname={pathname} variant="h2" classes={classes} />
        </div>
        <div className={classes.logoBox}>
          <Logo logoVariant="h5" classes={classes} />
        </div>
      </nav>
    </div>
  );
};
