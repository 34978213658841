import { createMuiTheme, responsiveFontSizes } from "@material-ui/core/styles";

// font-family: 'Exo', sans-serif;
// font-family: 'Raleway', sans-serif;

let theme = createMuiTheme({
  typography: {
    fontFamily: ["Exo", "sans-serif"],
    htmlFontSize: 16,
    fontSize: 14,
    h3: {
      letterSpacing: "0.05em"
    },
    caption: {
      letterSpacing: "0.05em"
    }
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920
    }
  },
  palette: {
    primary: { main: "#EFEFEF" },
    secondary: { main: "#707070" },
    pink: { main: "#F2AAAA" },
    mauve: { main: "#704F4F" },
    lightGray: { main: "#BDBDBD" }
  },
  drawerWidth: "14rem"
});

theme = responsiveFontSizes(theme);
export default theme;
