import React, { Fragment } from "react";

//MUI + Components
import MobilePageTitle from "../../common/typography/mobilePageTitle";
import Button from "@material-ui/core/Button";

//Styles & Images
import useStyles from "./Resume.style";
import resume from "../../../assets/resume.jpg";
import headshot1 from "../../../assets/180716_Griffith_Giulia_073-edit.jpg";

export default () => {
  const classes = useStyles();

  return (
    <Fragment>
      <MobilePageTitle variant="h4" title="RESUMÉ" />
      <div className={classes.container}>
        <div className={classes.left}>
          <img src={resume} className={classes.resume} alt="Resumé Preview" />
          <Button
            variant="contained"
            className={classes.button}
            onClick={() =>
              window.open(
                "https://firebasestorage.googleapis.com/v0/b/giulia-griffith.appspot.com/o/Resume%202019.pdf?alt=media&token=0357f8db-aede-4c31-9544-986662e073e2",
                "_blank"
              )
            }
          >
            Download Resumé
          </Button>
        </div>
        <div className={classes.right}>
          <div className={classes.headshotContainer}>
            <img
              src={headshot1}
              className={classes.image}
              alt="Headshot - Giulia Griffith"
            />
          </div>
        </div>
      </div>
    </Fragment>
  );
};
