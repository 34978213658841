import React, { Fragment } from "react";

//Components
import Nav from "../Nav";
import Logo from "../Logo";

//Styles & Images
import useStyles from "./Menu.style";

export default props => {
  const classes = useStyles();
  const { pathname } = props.location;

  return (
    <Fragment>
      <div className={classes.linksBox}>
        <Nav pathname={pathname} variant="h4" classes={classes} />
      </div>
      <div className={classes.logoBox}>
        <Logo logoVariant="h5" classes={classes} />
      </div>
    </Fragment>
  );
};
