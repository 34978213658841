import React, { Fragment } from "react";

//MUI + Components
import Typography from "@material-ui/core/Typography";
import MobilePageTitle from "../../common/typography/mobilePageTitle";
import useStyles from "./Content.style";

import Burlesque from "../../../assets/burlesque.png";
import IGTV from "../../../assets/icons8-igtv-32.png";

export default (props) => {
  const classes = useStyles();
  return (
    <Fragment>
      <MobilePageTitle variant="h4" title="CONTENT" />
      <div className={classes.container}>
        <section className={classes.section}>
          <div className={classes.left}>
            <Typography variant="body1" className={classes.text}>
              L-O-V-E
              <br />
              <br />
              Filmed/Edited by Pierre Marais
              <br />
              Choreographed by Krystyna Resavy
              <br />
              Music: L-O-V-E / Gregory Porter
            </Typography>
          </div>
          <div className={classes.right}>
            <iframe
              title="L-O-V-E"
              className={classes.video}
              src="https://www.youtube.com/embed/4wp4JaEpllI"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
          <div className={classes.scrollIcon} />
        </section>
        <section className={classes.section}>
          <div className={classes.left}>
            <Typography variant="body1">
              Welcome to Burlesque
              <br />
              <br />
              Directed and Choreographed by Omar Garibay
              <br />
              Produced by Black Haus Creative
            </Typography>
          </div>
          <div className={classes.right}>
            <div
              className={classes.imageContainer}
              style={{ position: "relative" }}
            >
              <a
                href="https://www.instagram.com/tv/CIJ4H8zl6PX"
                target="_blank"
                rel="noopener noreferrer"
              >
              <img
                src={Burlesque}
                className={classes.image}
                style={{ objectPosition: "bottom center" }}
                alt="Welcome to Burlesque"
              />
              <img src={IGTV} className={classes.igtv} alt="IGTV" />
              </a>
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};
