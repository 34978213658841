import React from "react";

//Routing
import indexRoutes from "../utils/routes/index";
import { Route, Switch } from "react-router-dom";

//MUI
import Hidden from "@material-ui/core/Hidden";

//Component
import Menu from "./common/menu/desktop/MenuComponent";
import MobileMenu from "./common/menu/mobile/MenuComponent";

//Styles
import useStyles from "./Internal.style";

// Route Handler
const switchRoutes = indexRoutes.map((prop, key) => {
  return <Route path={prop.path} component={prop.component} key={key} />;
});

export default () => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <Hidden xsDown>
        <div className={classes.menu}>
          <Route path="/:all" component={Menu} key={0} />
        </div>
      </Hidden>
      <Hidden smUp>
        <Route path="/:all" component={MobileMenu} key={0} />
      </Hidden>
      <Switch>{switchRoutes}</Switch>
    </div>
  );
};
