// ==========================================================================
// REACT, COMPONENTS AND OTHER RELATED PACKAGES
// ==========================================================================
import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";

//Firebase
import firebase from "firebase/app";
import "firebase/functions";
import "firebase/analytics";

// Stylesheet / Theme Imports
import theme from "./utils/styling/theme";
import { MuiThemeProvider } from "@material-ui/core/styles";
import CssBaseline from "@material-ui/core/CssBaseline";

//Components
import App from "./app/App";

// ==========================================================================
// FIREBASE, FIRESTORE SETUP
// ==========================================================================
const config = {
  apiKey: process.env.REACT_APP_APPLICATION,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  appId: process.env.REACT_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID
};

// Initialize an instance of firebase
firebase.initializeApp(config);
firebase.analytics();

export const functions = firebase.functions();

// ==========================================================================
// REACTDOM RENDER
// ==========================================================================

ReactDOM.render(
  <CssBaseline>
    <MuiThemeProvider theme={theme}>
      <App />
    </MuiThemeProvider>
  </CssBaseline>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
