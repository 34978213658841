import React from "react";

// React Router Setup
import { Router, Route, Switch } from "react-router-dom";
import history from "../utils/routes/history";

//Import Components / Pages
import Landing from "./pages/landing/LandingComponent";
import Internal from "./Internal";

export default () => (
  <Router history={history}>
    <Route
      render={({ location }) => (
        <Switch location={location}>
          <Route exact={true} path="/" component={Landing} />
          <Route component={Internal} />
        </Switch>
      )}
    />
  </Router>
);
