import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  container: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    overflowY: "scroll"
  },
  form: {
    padding: "2rem 4rem",
    "@media(max-width: 768px)": {
      padding: "2rem 2.5rem"
    }
  },
  input: {
    borderRadius: "10px",
    backgroundColor: "white"
  },
  //Image
  imageContainer: {
    width: "40%",
    alignSelf: "center",
    paddingTop: "2rem",
    "@media(max-width: 980px)": {
      width: "50%"
    },
    "@media(max-width: 768px)": {
      width: "65%"
    }
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    borderBottomRightRadius: "20px",
    borderTopLeftRadius: "20px"
  },
  //Error Message
  containerError: {
    display: "flex",
    overflow: "hidden"
  },
  iconError: {
    marginRight: "0.2rem",
    fontSize: "1.5em"
  },
  errorMessageContainer: {
    height: "2.5rem",
    marginTop: "0.4rem",
    "@media (min-width: 370)": {
      marginBottom: "-2.9rem"
    }
  },
  //Button
  containerButton: {
    display: "flex",
    justifyContent: "center",
    position: "relative"
  },
  buttonSuccess: {
    backgroundColor: "green",
    "&:hover": {
      backgroundColor: "darkgreen"
    }
  },
  buttonProgress: {
    color: "green",
    position: "absolute",
    top: "50%",
    left: "50%",
    marginTop: -12,
    marginLeft: -12
  }
});
