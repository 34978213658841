import React, { Fragment } from "react";
import { Link } from "react-router-dom";

//MUI
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";

import Facebook from "../../../assets/facebook.png";
import Instagram from "../../../assets/instagram.png";

function social(url) {
  window.open(url, "_blank");
}

export default props => {
  const { classes, logoVariant } = props;
  return (
    <Fragment>
      <div className={classes.socialBox}>
        <IconButton
          onClick={() => social("https://facebook.com/giulia.griffith")}
        >
          <img src={Facebook} className={classes.social} alt="Facebook" />
        </IconButton>
        <IconButton
          onClick={() => social("https://instagram.com/giulia_griffith")}
        >
          <img src={Instagram} className={classes.social} alt="Instagram" />
        </IconButton>
      </div>
      <Link to="/" className={classes.mainText}>
        <Typography variant={logoVariant} component="h1">
          GIULIA GRIFFITH
        </Typography>
      </Link>
    </Fragment>
  );
};
