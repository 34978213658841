import React from "react";
import Typography from "@material-ui/core/Typography";
import Hidden from "@material-ui/core/Hidden";

import useStyles from "./mobilePageTitle.style";

export default props => {
  const classes = useStyles();
  return (
    <Hidden smUp>
      <div className={classes.container}>
        <Typography variant={props.variant} className={classes.title}>
          {props.title.toUpperCase()}
        </Typography>
      </div>
    </Hidden>
  );
};
