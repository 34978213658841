import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../utils/styling/theme";

export default makeStyles({
  container: {
    position: "absolute",
    top: "1.7rem",
    left: "0.5rem"
  },
  title: {
    textDecoration: "none",
    position: "relative",
    color: theme.palette.pink.main,
    writingMode: "vertical-lr",
    textOrientation: "upright"
  }
});
