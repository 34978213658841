import React, { Fragment, useRef, useState } from "react";
import { Formik, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import ReCAPTCHA from "react-google-recaptcha";
import { functions } from "../../../index";

//Components & MUI
import renderCustomInput from "../../common/forms/InputComponent";
import renderErrorMessage from "../../common/forms/ErrorComponent";
import MobilePageTitle from "../../common/typography/mobilePageTitle";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";

//Styles
import useStyles from "./Contact.style";
import Headshot from "../../../assets/180716_Griffith_Giulia_121-edit.jpg";

//Icons
import Face from "@material-ui/icons/FaceOutlined";
import Email from "@material-ui/icons/EmailOutlined";
import Phone from "@material-ui/icons/PhoneRounded";
import Message from "@material-ui/icons/MessageOutlined";

const contactSchema = Yup.object().shape({
  name: Yup.string()
    .max(30, "Whoops, that's a little too long.")
    .required("Please, leave your name."),
  message: Yup.string()
    .max(1500, "Sorry, limited to 1500 characters!")
    .required("Please, leave a message."),
  email: Yup.string()
    .email("Whoops, is that correct?")
    .required("Please, leave your email address."),
  phone: Yup.number().typeError("Please, only numerals.")
});

//Component Flow
// 1) The button 'submit' is clicked
// 2) This executes the reCaptcha event
// 3) If reCaptcha is successful it begins the Formik 'handleSubmit' & resets the reCaptcha
// 4) onSubmit then runs, sending the values to the Cloud Function where Nodemailer sends an email
// 5) The response is returned - error or success

export default props => {
  const classes = useStyles();
  const recaptcha = useRef(null);
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  return (
    <Fragment>
      <MobilePageTitle variant="h4" title="CONTACT" />
      <div className={classes.container}>
        <Formik
          initialValues={{ name: "", message: "", email: "", phone: "" }}
          validationSchema={contactSchema}
          onSubmit={(values, actions) => {
            const submitForm = functions.httpsCallable("contactForm");
            submitForm({ values })
              .then(res => {
                actions.setSubmitting(false);
                setSuccess(true);
              })
              .catch(err => {
                setError(err);
                actions.setSubmitting(false);
              });
          }}
        >
          {({ handleSubmit, isSubmitting }) => (
            <Fragment>
              <ReCAPTCHA
                ref={recaptcha}
                onChange={value => {
                  if (value) {
                    handleSubmit();
                    recaptcha.current.reset();
                  }
                }}
                style={{ zIndex: "999" }}
                size="invisible"
                sitekey="6LfFV7MUAAAAAMxds_67ISgraIbKo4YfC04cckR7"
              />
              <div className={classes.imageContainer}>
                <img
                  src={Headshot}
                  className={classes.image}
                  style={{ objectPosition: "top right" }}
                  alt="Headshot - Giulia Griffith"
                />
              </div>
              <form className={classes.form}>
                <Field
                  component={renderCustomInput}
                  label="Name"
                  name="name"
                  disabled={success}
                  classes={classes}
                  icon={<Face />}
                />
                <div className={classes.errorMessageContainer}>
                  <ErrorMessage
                    name="name"
                    classes={classes}
                    component={renderErrorMessage}
                  />
                </div>
                <Field
                  component={renderCustomInput}
                  label="Email Address"
                  name="email"
                  disabled={success}
                  classes={classes}
                  icon={<Email />}
                />
                <div className={classes.errorMessageContainer}>
                  <ErrorMessage
                    name="email"
                    disabled={success}
                    classes={classes}
                    component={renderErrorMessage}
                  />
                </div>
                <Field
                  component={renderCustomInput}
                  label="Phone Number (Optional)"
                  name="phone"
                  disabled={success}
                  classes={classes}
                  icon={<Phone />}
                />
                <div className={classes.errorMessageContainer}>
                  <ErrorMessage
                    name="phone"
                    classes={classes}
                    component={renderErrorMessage}
                  />
                </div>
                <Field
                  component={renderCustomInput}
                  label="Message"
                  name="message"
                  disabled={success}
                  multiline={true}
                  rows={5}
                  classes={classes}
                  icon={<Message />}
                />
                <div className={classes.errorMessageContainer}>
                  <ErrorMessage
                    name="message"
                    classes={classes}
                    component={renderErrorMessage}
                  />
                </div>
                <div className={classes.containerButton}>
                  <Button
                    variant="contained"
                    onClick={() => !success && recaptcha.current.execute()}
                    className={success ? classes.buttonSuccess : classes.button}
                  >
                    {success ? "Sent!" : error ? "Try Again" : "Submit"}
                  </Button>
                  {isSubmitting && (
                    <CircularProgress
                      size={24}
                      className={classes.buttonProgress}
                    />
                  )}
                </div>
              </form>
            </Fragment>
          )}
        </Formik>
      </div>
    </Fragment>
  );
};
