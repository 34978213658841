import React from "react";
import { Link } from "react-router-dom";

//MUI
import Typography from "@material-ui/core/Typography";
import IconButton from "@material-ui/core/IconButton";

//Styles
import useStyles from "./Landing.style";

//Decide and remove other images
import NY from "../../../assets/DSCF3097-Edit.jpg";
import Facebook from "../../../assets/facebook.png";
import Instagram from "../../../assets/instagram.png";

export default props => {
  const classes = useStyles();

  function social(url) {
    window.open(url, "_blank");
  }

  return (
    <div className={classes.container}>
      <div className={classes.imageContainer}>
        <img
          src={NY}
          className={classes.image}
          alt="NYC Shoot - Giulia Griffith"
        />
      </div>
      <div className={classes.menuContainer}>
        <div className={classes.mainText}>
          <Typography variant="h3" component="h1">
            GIULIA GRIFFITH
          </Typography>
          <Typography variant="caption">CLASSICAL DANCER - NYC</Typography>
        </div>
        <div className={classes.linksBox}>
          <Link className={classes.link} to="/content">
            <Typography variant="h6">CONTENT</Typography>
          </Link>
          <Link className={classes.link} to="/cast-me">
            <Typography variant="h6">RESUMÉ</Typography>
          </Link>
          <Link className={classes.link} to="/bio">
            <Typography variant="h6">BIO</Typography>
          </Link>
          <Link className={classes.link} to="/reach-out">
            <Typography variant="h6">CONTACT</Typography>
          </Link>
        </div>
        <div className={classes.linksBox}>
          <IconButton
            onClick={() => social("https://facebook.com/giulia.griffith")}
          >
            <img src={Facebook} className={classes.social} alt="Facebook" />
          </IconButton>
          <IconButton
            onClick={() => social("https://instagram.com/giulia_griffith")}
          >
            <img src={Instagram} className={classes.social} alt="Instagram" />
          </IconButton>
        </div>
      </div>
    </div>
  );
};
