import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  container: {
    display: "flex",
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    overflow: "hidden",
    "@media (max-width: 600px)": {
      flexDirection: "column"
    }
  },
  imageContainer: {
    height: "100%",
    width: "50%",
    "@media (max-width: 600px)": {
      height: "60%",
      width: "100%"
    }
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "right bottom"
  },
  menuContainer: {
    height: "100%",
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    "@media (max-width: 600px)": {
      height: "40%",
      width: "100%"
    }
  },
  linksBox: {
    display: "flex"
  },
  link: {
    "&:not(:last-child)": {
      marginRight: "1rem"
    },
    textDecoration: "none",
    color: "inherit",
    transition: "color 0.5s",
    "&:hover": {
      color: "lightgray"
    }
  },
  mainText: {
    marginBottom: "1.5rem",
    textAlign: "center"
  },
  social: {
    height: "1rem"
  }
});
