import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../../utils/styling/theme";

export default makeStyles({
  navigation: {
    height: "48px",
    width: "48px",
    position: "absolute"
  },
  checkbox: {
    display: "none",
    "&:checked ~ $background": {
      transform: "scale(80)"
    },
    "&:checked ~ $nav": {
      opacity: "1",
      width: "100%",
      visibility: "visible"
    },

    "&:checked + $navigationButton": {
      backgroundColor: "transparent"
    },
    "&:checked + $navigationButton $icon ": {
      backgroundColor: "transparent"
    },
    "&:checked + $navigationButton $icon::before ": {
      top: "0",
      transform: "rotate(135deg)"
    },
    "&:checked + $navigationButton $icon::after ": {
      top: "0",
      transform: "rotate(-135deg)"
    }
  },
  navigationButton: {
    backgroundColor: theme.palette.primary.main,
    height: "3rem",
    width: "3rem",
    borderRadius: "50%",
    position: "fixed",
    top: "1rem",
    right: "0.5rem",
    zIndex: "2000",
    textAlign: "center",
    cursor: "pointer"
  },
  icon: {
    position: "relative",
    marginTop: "1.5rem",

    "&, &::before, &::after": {
      width: "1rem",
      height: "1px",
      backgroundColor: "#000000",
      display: "inline-block"
    },
    "&::before, &::after": {
      content: "''",
      position: "absolute",
      left: "0",

      transition: "all .2s"
    },
    "&::before": {
      top: "-.35rem"
    },
    "&::after": {
      top: ".35rem"
    }
  },
  background: {
    height: "2rem",
    width: "2rem",
    borderRadius: "50%",
    position: "fixed",
    top: "1.5rem",
    right: "1rem",
    backgroundColor: theme.palette.lightGray.main,
    zIndex: "1000",
    transition: "transform .8s cubic-bezier(0.86, 0 , 0.07, 1)"
  },
  nav: {
    height: "100%",
    position: "fixed",
    top: "0",
    left: "0",
    zIndex: "1500",
    opacity: "0",
    width: "0",
    visibility: "hidden",
    transition: "all .8s cubic-bezier(0.68, -0.55, 0.265, 1.55)",
    overflowY: "scroll",

    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column"
  },
  link: {
    textDecoration: "none",
    position: "relative",
    color: theme.palette.primary.main,
    transition: "all .6s ease",
    paddingBottom: "1rem",
    "&:hover": {
      color: "black"
    },
    "&::before": {
      transition: "width .2s linear",
      content: "''",
      width: "0px",
      top: "32%",
      position: "absolute",
      left: "-45px",
      height: "2px",
      background: "black"
    },
    "&::after": {
      transition: "width .2s linear",
      content: "''",
      width: "0px",
      top: "32%",
      position: "absolute",
      right: "-45px",
      height: "2px",
      background: "black"
    },
    "&:hover&::before": {
      width: "35px"
    },
    "&:hover&::after": {
      width: "35px"
    }
  },
  active: {
    color: "black",
    position: "relative",
    "&::before": {
      content: "''",
      width: "35px",
      top: "32%",
      position: "absolute",
      left: "-45px",
      height: "2px",
      background: "black"
    },
    "&::after": {
      content: "''",
      width: "35px",
      top: "32%",
      position: "absolute",
      right: "-45px",
      height: "2px",
      background: "black"
    }
  },
  social: {
    height: "1rem"
  },
  mainText: {
    textDecoration: "none",
    color: "black"
  },
  linksBox: {
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
    flexGrow: 1,
    justifyContent: "center",
    alignItems: "center"
  },
  logoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    padding: "2rem"
  }
});
