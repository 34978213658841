import { makeStyles } from "@material-ui/core/styles";
import theme from "../../../../utils/styling/theme";

export default makeStyles({
  linksBox: {
    display: "flex",
    flexDirection: "column",
    padding: "2rem",
    flexGrow: 1,
    justifyContent: "center"
  },
  link: {
    textDecoration: "none",
    position: "relative",
    color: theme.palette.pink.main,
    transition: "all .6s ease",
    paddingBottom: "1rem",
    "&:hover": {
      color: "black",
      marginLeft: "0.7rem"
    },
    "&::before": {
      transition: "width .2s linear",
      content: "''",
      width: "0px",
      top: "32%",
      position: "absolute",
      left: "-45px",
      height: "2px",
      background: "black"
    },
    "&:hover&::before": {
      width: "35px"
    }
  },
  mainText: {
    textDecoration: "none",
    color: "black"
  },
  active: {
    color: "black",
    position: "relative",
    marginLeft: "0.7rem",
    "&::before": {
      content: "''",
      width: "35px",
      top: "32%",
      position: "absolute",
      left: "-45px",
      height: "2px",
      background: "black"
    }
  },
  social: {
    height: "1rem"
  },
  logoBox: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    paddingBottom: "1rem"
  }
});
