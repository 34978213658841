import Resume from "../../app/pages/resume/ResumeComponent";
import Content from "../../app/pages/content/ContentComponent";
import Bio from "../../app/pages/bio/BioComponent";
import Contact from "../../app/pages/contact/ContactComponent";
import NoMatch from "../../app/common/errors/nomatch";

export default [
  {
    path: "/content",
    component: Content,
  },
  {
    path: "/cast-me",
    component: Resume,
  },
  {
    path: "/bio",
    component: Bio,
  },
  {
    path: "/reach-out",
    component: Contact,
  },
  { component: NoMatch },
];
