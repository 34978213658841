import { makeStyles } from "@material-ui/core/styles";

export default makeStyles({
  container: {
    display: "flex",
    flexGrow: 1,
    //Sets height of container and children on large screens
    height: "100vh",
    //Below 768px - iPad tablet width
    "@media(max-width: 768px)": {
      display: "block",
      height: "unset",
      overflowY: "scroll"
    }
  },
  //Headshot = Right
  right: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "50%",
    //Below 768px - iPad tablet width
    "@media(max-width: 768px)": {
      width: "100%",
      padding: "0 2rem 2rem 2rem"
    }
  },
  //Resumé = Left
  left: {
    width: "50%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    //Below 700px
    "@media(max-width: 768px)": {
      width: "100%",
      padding: "2rem"
    }
  },
  headshotContainer: {
    width: "60%",
    height: "85%",
    "@media(max-width: 1280px)": {
      width: "70%",
      height: "85%"
    },
    "@media(max-width: 960px)": {
      width: "85%",
      height: "unset"
    },
    "@media(max-width: 768px)": {
      width: "90%"
    }
  },
  image: {
    width: "100%",
    height: "100%",
    objectFit: "cover",
    objectPosition: "50% 50%",
    borderBottomRightRadius: "20px",
    borderTopLeftRadius: "20px"
  },
  resume: {
    borderRadius: "20px",
    width: "70%",
    "@media(max-width: 1280px)": {
      width: "70%"
    },
    "@media(max-width: 960px)": {
      width: "85%"
    },
    "@media(max-width: 768px)": {
      width: "80%"
    }
  },
  button: {
    marginTop: "2rem",
    "@media(max-width: 768px)": {
      marginTop: "1rem"
    }
  }
});
