import React, { Fragment } from "react";

//MUI + Components
import Typography from "@material-ui/core/Typography";
import MobilePageTitle from "../../common/typography/mobilePageTitle";
import useStyles from "./Bio.style";

//Images
import NY from "../../../assets/DSCF3811.jpg";
import NY1 from "../../../assets/DSCF3018.jpg";
import NY2 from "../../../assets/DSCF3778.jpg";

export default props => {
  const classes = useStyles();

  return (
    <Fragment>
      <MobilePageTitle variant="h4" title="BIO" />
      <div className={classes.container}>
        <section className={classes.section}>
          <div className={classes.left}>
            <Typography variant="body1">
              Giulia started her career dancing for Barry Manilow’s
              entertainment company onboard Holland America Cruise Lines
              sparking her love of travel.
              <br />
              <br />
              Giulia has worked abroad in Abu Dhabi, dancing as a Soloist for
              Ferrari, and also dancing as Roxanne in <em>Moulin Rouge</em> at
              the Burj al-Arab in Dubai. After the UAE, she did two contracts in
              Germany in the Cirque Show <em>Pallazzo Varieté</em> as a
              principal dancer, completing a wonderful feat of dancing on all
              seven continents.
            </Typography>
          </div>
          <div className={classes.right}>
            <div className={classes.imageContainer}>
              <img
                src={NY}
                className={classes.image}
                style={{ objectPosition: "50% 50%" }}
                alt="NYC Shoot 1 - Giulia Griffith"
              />
            </div>
          </div>
          <div className={classes.scrollIcon} />
        </section>
        <section className={classes.section}>
          <div className={classes.left}>
            <Typography variant="body1">
              Stateside, Giulia has danced under the direction of Frank Gatson
              Jr. (choreographer of Beyoncé's Single Ladies) in the revamp of
              <em>Jubilee!</em> in Las Vegas. She has performed at Lincoln
              Center's <em>Hall Of Fame Gala</em> dancing for the induction of
              Louis Armstrong, Audra McDonald and Hal Prince (dir Christopher
              D’amboise). <br /> <br />
              Giulia was also a principal track in New York City’s debut of Vox
              Lumier’s
              <em>Fate Still Rests</em> (dir. by Antoinette DiPetropolo).
            </Typography>
          </div>
          <div className={classes.right}>
            <div className={classes.imageContainer}>
              <img
                src={NY1}
                className={classes.image}
                style={{ objectPosition: "bottom center" }}
                alt="NYC Shoot 2 - Giulia Griffith"
              />
            </div>
          </div>
          <div className={classes.scrollIcon} />
        </section>
        <section className={classes.section}>
          <div className={classes.left}>
            <Typography variant="body1">
              Recently she danced in the Opening of The Macy’s Thanksgiving Day
              Parade as a Macy’s Starlette! <br /> <br />
              Other credits include The <em>Nutcracker</em> (Snow Queen, Dew
              Drop, and Sugar Plum Fairy (New Jersey Foundation for Dance and
              Theatre Arts), <em>Fiends</em> (Nurse Serenity) Busch Gardens,{" "}
              <em>Christmastown</em> (Swing, Dance Captain) Busch Gardens,{" "}
              <em>Barry Manilow’s Copacabana</em> (Myrna) Theatrezone.
            </Typography>
          </div>
          <div className={classes.right}>
            <div className={classes.imageContainer}>
              <img
                src={NY2}
                className={classes.image}
                style={{ objectPosition: "bottom center" }}
                alt="NYC Shoot 3 - Giulia Griffith"
              />
            </div>
          </div>
        </section>
      </div>
    </Fragment>
  );
};
