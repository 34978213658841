import React from "react";

// Material UI Core
import InputAdornment from "@material-ui/core/InputAdornment";
import TextField from "@material-ui/core/TextField";

export default ({
  form, //Helps to pass error through
  field, //Passes all props from Formik Field Component
  label, //Passes labelText
  icon, //Passes endAdornment icon where required
  type, //Specifies type of input if required
  multiline, //If specified
  rows, //If above is specified
  disabled,
  classes,
  ...props
}) => (
  <TextField
    error={form.errors[field.name] && form.touched[field.name]}
    label={label}
    type={type}
    multiline={multiline}
    rows={rows}
    disabled={disabled}
    fullWidth
    variant="filled"
    InputProps={{
      ...field,
      ...props,
      classes: {
        root: classes.input
      },
      disableUnderline: true,
      endAdornment: <InputAdornment position="end">{icon}</InputAdornment>
    }}
  />
);
